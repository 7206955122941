.index-module__searchPageContainer___J14TX {
  max-width: 1280px;
  width: 100%;
  margin: auto;
}
@media (max-width: 639px) {
  .index-module__searchPageContainer___J14TX {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.index-module__loginLink___cfyJ0 {
  text-align: right;
  font-size: 12px;
  line-height: 20px;
  padding-top: 10px;
}
.index-module__searchNav___HHNH4 {
  display: flex;
  margin-top: 1em;
  color: var(--text-search);
}
.index-module__searchForm___7IF5F {
  display: flex;
  flex: 1;
  padding: 2px;
  border: 1px solid var(--box-border);
  border-radius: 6px;
}
.index-module__searchForm___7IF5F:focus-within {
  border: 1px solid var(--box-border-focus);
}
.index-module__searchForm___7IF5F .index-module__searchBar___qQvjz {
  font-size: 16px;
  line-height: 22px;
  border: none;
  background: transparent;
  margin: 0;
  padding: 7px 8px;
  border-radius: inherit;
  flex: 1;
}
.index-module__searchForm___7IF5F .index-module__searchBar___qQvjz:focus {
  border: none;
  outline: none;
}
.index-module__searchForm___7IF5F .index-module__submitButton___CO2kv {
  text-indent: -999px;
  overflow: hidden;
  width: 40px;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: inherit;
  background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat center;
  cursor: pointer;
  opacity: 0.6;
}
.index-module__searchForm___7IF5F .index-module__submitButton___CO2kv:hover {
  opacity: 1;
}
.index-module__searchForm___7IF5F .index-module__submitButton___CO2kv:focus {
  border: none;
  outline: none;
}
.index-module__searchForm___7IF5F .index-module__submitButton___CO2kv:hover {
  opacity: 1;
}
.index-module__randomButton___DmrsG {
  font-size: 16px;
  line-height: 22px;
  margin-left: 10px;
  border: 1px solid var(--box-border);
  border-radius: 6px;
  background-color: var(--button-background);
}
.index-module__randomButton___DmrsG:hover {
  border: 1px solid var(--box-border-focus);
  background-color: var(--button-background-focus);
}
.index-module__quoteItem___8oprq {
  padding: 15px 10px;
  border-top: 1px solid #eef0ec;
}
.index-module__quoteItem___8oprq:first-child {
  border-top: none;
}
.index-module__quoteItem___8oprq .index-module__quoteMessage___KYYln {
  font-size: 18px;
  line-height: 24px;
}
.index-module__quoteItem___8oprq .index-module__quoteMessage___KYYln blockquote {
  padding: 0 1em;
  border-left: 0.25em solid #dadada;
}
.index-module__quoteItem___8oprq .index-module__quoteMessage___KYYln code {
  padding: 0.2em 0.4em;
  font-size: 85%;
  border-radius: 6px;
  background-color: #f7f7f5;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}
.index-module__quoteItem___8oprq .index-module__quoteMessage___KYYln pre {
  padding: 16px;
  font-size: 85%;
  overflow: auto;
  background-color: #f7f7f5;
  border-radius: 6px;
}
.index-module__quoteItem___8oprq .index-module__quoteMessage___KYYln pre > code {
  font-size: unset;
  border-radius: unset;
  background-color: unset;
  padding: unset;
}
.index-module__quoteItem___8oprq .index-module__quoteInfo___xViDX,
.index-module__quoteItem___8oprq .index-module__quoteMeta___6gWTu {
  font-size: 14px;
  line-height: 20px;
  display: flex;
  gap: 5px;
  color: var(--palatte-fg);
  opacity: 55%;
}
.index-module__pageButtons___MzD67 {
  padding-top: 30px;
  padding-left: 10px;
  padding-bottom: 20px;
  font-size: 14px;
  line-height: 20px;
  color: var(--palatte-fg);
  opacity: 65%;
}